import { useContext } from 'react';
import ActionCableContext from 'contexts/ActionCableContext';

export const useActionCableConnection = () => {
  const context = useContext(ActionCableContext);

  if (!context) {
    throw new Error('useActionCableConnection deve ser usado dentro de um ActionCableProvider');
  }

  return context;
};
