import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useActionCableConnection } from 'hooks/useActionCable';
import toast from 'utils/ToastNotistack';
import axiosServices from 'utils/axios';
import { Box, CircularProgress, Typography, Stack } from '@mui/material';

// Interface for individual print job
interface PrintJob {
  organizationId: string;
  documentId: string;
  subscription: any;
  isPending: boolean;
  capsule_name?: string;
  number?: string;
}

interface PrintReportContextType {
  hasPendingDownloads: boolean;
  requestPrintReport: (
    organizationId: string | undefined,
    documentId: string | undefined,
    number?: string,
    capsule_name?: string
  ) => Promise<void>;
  cancelPendingDownload: (documentId?: string) => void;
}

const PrintReportContext = createContext<PrintReportContextType | null>(null);

export const usePrintReport = () => {
  const context = useContext(PrintReportContext);
  if (!context) {
    throw new Error('usePrintReport must be used within a PrintReportProvider');
  }
  return context;
};

interface PrintReportProviderProps {
  children: ReactNode;
}

export const PrintReportProvider: React.FC<PrintReportProviderProps> = ({ children }) => {
  const [printJobs, setPrintJobs] = useState<PrintJob[]>([]);
  const { subscribeToChannel, unsubscribeFromChannel } = useActionCableConnection();

  const hasPendingDownloads = printJobs.some((job) => job.isPending);

  useEffect(() => {
    return () => {
      console.log('[PrintReportContext] Provider unmounting, unsubscribing from all channels');
      printJobs.forEach((job) => {
        if (job.subscription) {
          unsubscribeFromChannel(job.subscription);
        }
      });
      setPrintJobs([]);
    };
  }, []);

  const requestPrintReport = async (
    organizationId: string | undefined,
    documentId: string | undefined,
    number?: string,
    capsule_name?: string
  ) => {
    if (!organizationId || !documentId) {
      return;
    }

    try {
      const existingJobIndex = printJobs.findIndex((job) => job.documentId === documentId);
      if (existingJobIndex >= 0 && printJobs[existingJobIndex].isPending) {
        return;
      }

      const channel = subscribeToChannel(
        {
          channel: 'ReportChannel',
          organization_id: organizationId,
          document_id: documentId
        },
        (data) => {
          if (data.event === 'pdf_ready' && data.pdf_url) {
            const fullPdfUrl = data.pdf_url.startsWith('http') ? data.pdf_url : `${process.env.REACT_APP_URL_BACKEND}${data.pdf_url}`;
            try {
              fetch(fullPdfUrl)
                .then((response) => response.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `${capsule_name} - ${number}.pdf`);
                  link.style.display = 'none';
                  document.body.appendChild(link);
                  link.click();

                  window.URL.revokeObjectURL(url);
                  document.body.removeChild(link);
                })
                .catch((err) => {
                  const link = document.createElement('a');
                  link.href = fullPdfUrl;
                  link.setAttribute('download', `${capsule_name} - ${number}.pdf`);
                  link.target = '_blank';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });
            } catch (err) {
              window.open(fullPdfUrl, '_blank');
            }

            setPrintJobs((currentJobs) => currentJobs.map((job) => (job.documentId === documentId ? { ...job, isPending: false } : job)));

            setPrintJobs((currentJobs) => {
              const jobToUpdate = currentJobs.find((job) => job.documentId === documentId);
              if (jobToUpdate && jobToUpdate.subscription) {
                try {
                  unsubscribeFromChannel(jobToUpdate.subscription);
                } catch (err) {}
              }
              return currentJobs.filter((job) => job.documentId !== documentId);
            });
          } else if (data.event === 'pdf_error') {
            console.error('[PrintReportContext] PDF error for document', documentId);

            toast(`Não foi possível gerar o arquivo para o ${capsule_name} - ${number}.`, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }
            });

            setPrintJobs((currentJobs) => {
              const jobToUpdate = currentJobs.find((job) => job.documentId === documentId);
              if (jobToUpdate && jobToUpdate.subscription) {
                try {
                  unsubscribeFromChannel(jobToUpdate.subscription);
                } catch (err) {
                  console.error('[PrintReportContext] Error unsubscribing:', err);
                }
              }

              return currentJobs.filter((job) => job.documentId !== documentId);
            });
          }
        }
      );

      if (channel) {
        if (existingJobIndex >= 0) {
          setPrintJobs((currentJobs) =>
            currentJobs.map((job, index) => (index === existingJobIndex ? { ...job, subscription: channel, isPending: true } : job))
          );
        } else {
          setPrintJobs((currentJobs) => [
            ...currentJobs,
            {
              organizationId: organizationId as string,
              documentId: documentId as string,
              capsule_name,
              number,
              subscription: channel,
              isPending: true
            }
          ]);
        }

        await axiosServices.post(`v1/organizations/${organizationId}/documents/${documentId}/print`, {});

        toast(`Solicitação para o ${capsule_name} - ${number} enviada com sucesso.`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
      } else {
        toast('Não foi possível estabelecer conexão.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
      }
    } catch (err: any) {
      console.error('[PrintReportContext] Error for document', documentId, ':', err);

      toast(`Erro ao solicitar o ${capsule_name} - ${number}.`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });

      setPrintJobs((currentJobs) => currentJobs.filter((job) => job.documentId !== documentId));
    }
  };

  const cancelPendingDownload = (documentId?: string) => {
    if (documentId) {
      setPrintJobs((currentJobs) => {
        const jobToCancel = currentJobs.find((job) => job.documentId === documentId);
        if (jobToCancel && jobToCancel.subscription) {
          try {
            unsubscribeFromChannel(jobToCancel.subscription);
          } catch (err) {
            console.error('[PrintReportContext] Error unsubscribing:', err);
          }
        }
        return currentJobs.filter((job) => job.documentId !== documentId);
      });
    } else {
      printJobs.forEach((job) => {
        if (job.subscription) {
          try {
            unsubscribeFromChannel(job.subscription);
          } catch (err) {
            console.error('[PrintReportContext] Error unsubscribing:', err);
          }
        }
      });
      setPrintJobs([]);
    }
  };

  return (
    <PrintReportContext.Provider
      value={{
        hasPendingDownloads,
        requestPrintReport,
        cancelPendingDownload
      }}
    >
      {children}
      {printJobs.filter((job) => job.isPending).length > 0 && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            left: 16,
            zIndex: 9999,
            backgroundColor: 'background.paper',
            boxShadow: 3,
            borderRadius: 2,
            p: 2,
            maxWidth: '500px'
          }}
        >
          <Stack spacing={1}>
            {printJobs
              .filter((job) => job.isPending)
              .map((job) => (
                <Box key={job.documentId} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <CircularProgress size={24} />
                  <Typography variant="body2">
                    Preparando o {job.capsule_name} - {job.number || job.documentId}. Aguarde...
                  </Typography>
                </Box>
              ))}
          </Stack>
        </Box>
      )}
    </PrintReportContext.Provider>
  );
};

export default PrintReportContext;
