import React, { createContext, useEffect, useState, useCallback } from 'react';
import { createConsumer } from '@rails/actioncable';
import useAuth from 'hooks/useAuth';

type ActionCableContextType = {
  cable: any;
  subscribeToChannel: (channelParams: object, receivedCallback: (data: any) => void) => any;
  unsubscribeFromChannel: (channelSubscription: any) => void;
};

const ActionCableContext = createContext<ActionCableContextType | null>(null);

export const ActionCableProvider = ({ children }: { children: React.ReactNode }) => {
  const [cable, setCable] = useState<any>(null);
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const authHeadersStr = localStorage.getItem('0paper-auth-headers');
    const authHeaders = authHeadersStr ? JSON.parse(authHeadersStr) : {};

    const accessToken = authHeaders['access-token'];
    const client = authHeaders.client;
    const uid = authHeaders.uid;

    const organizationId = localStorage.getItem('0paper-current-organization');

    const actionCableUrl = `${process.env.REACT_APP_ACTION_CABLE_URL}?uid=${uid}&client=${client}&access-token=${accessToken}&organization_id=${organizationId}`;

    const newCable = createConsumer(actionCableUrl);
    setCable(newCable);

    return () => {
      if (newCable) newCable.disconnect();
    };
  }, [isLoggedIn]);

  const subscribeToChannel = useCallback(
    (channelParams: object, receivedCallback: (data: any) => void) => {
      if (!cable) return null;

      const subscription = cable.subscriptions.create(channelParams, {
        received: (data: any) => {
          receivedCallback(data);
        },
        connected: () => {
          // console.log('Conectado ao canal:', channelParams);
        },
        disconnected: () => {
          // console.log('Desconectado do canal:', channelParams);
        },
        rejected: () => {
          // console.warn('Assinatura rejeitada:', channelParams);
        }
      });

      return subscription;
    },
    [cable]
  );

  const unsubscribeFromChannel = useCallback(
    (channelSubscription: any) => {
      if (channelSubscription && cable) {
        cable.subscriptions.remove(channelSubscription);
        // console.log('Assinatura removida do canal.');
      }
    },
    [cable]
  );

  return (
    <ActionCableContext.Provider value={{ cable, subscribeToChannel, unsubscribeFromChannel }}>{children}</ActionCableContext.Provider>
  );
};

export default ActionCableContext;
